import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//import MainPage from "./pages/mainPage";
import DumpIdeas from "./pages/dumpIdeas";
import ShitIdeas from "./pages/shitIdeasv2";
import IntroPage from "./pages/introPage";
import MainPage from "./pages/mainPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IntroPage />} />
        <Route path="/home" element={<MainPage />} />
        <Route path="/add" element={<DumpIdeas />} />
        <Route path="generate" element={<ShitIdeas />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
