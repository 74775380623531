import React from "react";

type Props = {
  word: string;
  className: string;
  state: boolean;
  onClick: React.Dispatch<React.SetStateAction<any>>;
};

const WordState = ({ word, className, state, onClick }: Props) => {
  return (
    <span className=" position-relative" onClick={onClick}>
      <span className={`${className}`}>{word ? word : "WORD"}</span>
      <legend className={`sub-text `}>
        {state ? (
          <img src="images/lock.png" width="25%" alt="locked" />
        ) : (
          <img src="images/unlock.png" width="25%" alt="unlocked" />
        )}
      </legend>
    </span>
  );
};

export default WordState;
