import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router";

const MainPage = () => {
  const [data, setData] = useState(0);
  let navigate = useNavigate();

  const fetchPossibilities = async () => {
    await fetch("https://dump-ideas-api.herokuapp.com/getPossibilities")
      .then((res) =>
        res.json().then((r) => {
          console.log(r);
          setData(r.count);
        })
      )
      .catch((r) => {
        console.log("Error", r);
      });
  };

  useEffect(() => {
    fetchPossibilities();
  }, []);

  return (
    <Container fluid className=" ">
      {/* Desktop */}
      <Container className="w-50 justify-content-center text-center pt-5 mt-5 d-none d-lg-block">
        <Row className="w-100 blend-multiply text-center ">
          <img src="/images/intro-stack2.png" alt="intro" />
        </Row>
        <Row className="my-md-3 w-100">
          <mark>
            <span className="big-generate-text font-ultra pt-5">
              {data.toLocaleString("en-US")}
            </span>
          </mark>
        </Row>
        <Row className=" w-100 blend-multiply text-center">
          <img src="/images/intro-stack1.png" alt="intro" />
        </Row>
      </Container>

      {/* Mobile*/}
      <Container className=" mx-auto pt-5 mt-5 text-center  justify-content-center  d-lg-none">
        <Row className="blend-multiply text-center pb-2 ">
          <img src="/images/intro-stack2.png" alt="intro" />
        </Row>
        <Row className="mx-auto">
          <mark>
            <div className="big-generate-text font-ultra">
              {data.toLocaleString("en-US")}
            </div>
          </mark>
        </Row>
        <Row className="blend-multiply text-center pt-2 ">
          <img src="/images/intro-stack1.png" alt="intro" />
        </Row>
      </Container>

      <Row className="justify-content-center text-center mt-5 pt-5">
        <Col className="">
          <button
            className="crap-button-black-lg font-bold"
            onClick={() => {
              navigate(`/add`);
            }}
          >
            ADD IDEAS
          </button>
          <h6 className="mt-2">HELP A COLLEAGUE</h6>
        </Col>
        <Col className="pt-3 ">
          <button
            className="crap-button-outlined-lg font-bold"
            onClick={() => {
              navigate(`/generate`);
            }}
          >
            REMIX IDEAS
          </button>
          <h6 className="mt-2">HELP YOURSELF</h6>
        </Col>
      </Row>
    </Container>
  );
};

export default MainPage;
