import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <Row className=" ps-3 pt-5">
      <Link to="/home" className="text-decoration-none text-blac k">
        <div className="font-bold crap-button-outlined">HOME</div>
      </Link>
    </Row>
  );
};

export default Header;
