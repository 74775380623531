import React from "react";
import { Row } from "react-bootstrap";

const Footer = () => {
  return (
    <Row className="justify-content-center fixed-bottom pb-4">
      <img
        src="/images/powered.png"
        alt="poweredbybbdo"
        className="footer-img"
      />
    </Row>
  );
};

export default Footer;
