/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Footer from "../components/footer";
import { Link } from "react-router-dom";

const IntroPage = () => {
  return (
    <>
      <Container className="pt-5 mt-4 ">
        {/** Desktop */}
        <Row className="mx-auto intro-image-adjust blend-multiply text-center d-sm-none d-none d-lg-block ps-5 pb-0 mb-0 ">
          <img
            src="/images/landingpage-d.png"
            alt="intro"
            className="pe-0 ps-0"
          />
        </Row>
        {/*
        <Row className="mx-auto ps-3 intro-paragraph-adjust text-center d-sm-none d-none d-lg-block pb-0 mb-0 text-regular font-medium ">
          Add in as many words as you like, and the site will randomly remix
          them into thousands of interesting connections. Helping you find new
          directions, or spark your next ideas.
        </Row>

  */}

        <Row className="mx-auto w-50 text-center d-sm-none d-none d-lg-block pt-5 mt-0">
          <Link to="/home" className="text-decoration-none text-black">
            <div className="bold-text crap-button-black-lg t">START</div>
          </Link>
        </Row>

        {/** Mobile */}
        <Row className="mx-auto w-100 blend-multiply d-lg-none d-block pt-3 ">
          <img src="/images/landingpage-m.png" alt="intro" />
        </Row>

        <Row className="mx-auto text-center d-lg-none d-block">
          {/* 
          <p className="font-medium">
            Add in as many words as you like, and the site will randomly remix
            them into thousands of interesting connections. Helping you find new
            directions, or spark your next ideas.
          </p>
*/}
          <Link to="/home" className="text-decoration-none text-black">
            <div className="bold-text crap-button-black-lg  mt-5 mx-auto">
              START
            </div>
          </Link>
        </Row>

        <Footer />
      </Container>
    </>
  );
};

export default IntroPage;
