/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Header from "../components/header";
import WordState from "../components/wordState";
import { Link, useNavigate } from "react-router-dom";

const ShitIdeas = () => {
  let navigate = useNavigate();

  const [verb, setVerb] = useState({
    word: "",
    locked: false,
  });
  const [noun, setNoun] = useState({
    word: "",
    locked: false,
  });
  const [product, setProduct] = useState({
    word: "",
    locked: false,
  });
  const [article, setArticle] = useState("A");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchRandomVerb = async () => {
    await fetch("https://dump-ideas-api.herokuapp.com/getRandomVerb")
      .then((res) => res.json().then((r) => setVerb({ ...verb, word: r.verb })))
      .catch((r) => {
        console.log("Error", r);
      });
  };
  const fetchRandomNoun = async () => {
    await fetch("https://dump-ideas-api.herokuapp.com/getRandomNoun")
      .then((res) => res.json().then((r) => setNoun({ ...noun, word: r.noun })))
      .catch((r) => {
        console.log("Error", r);
      });
  };
  const fetchRandomProduct = async () => {
    await fetch("https://dump-ideas-api.herokuapp.com/getProduct")
      .then((res) =>
        res.json().then((r) => {
          if (
            r.product.charAt(0) === "N" ||
            r.product.charAt(0) === "A" ||
            r.product.charAt(0) === "E"
          ) {
            setArticle("AN");
          } else {
            setArticle("A");
          }
          setProduct({ ...product, word: r.product });
        })
      )
      .catch((r) => {
        console.log("Error", r);
      });
  };

  const generateIdea = useCallback(() => {
    if (!verb.locked) {
      fetchRandomVerb();
    }
    if (!noun.locked) {
      fetchRandomNoun();
    }
    if (!product.locked) {
      fetchRandomProduct();
    }
  }, [
    verb.locked,
    noun.locked,
    product.locked,
    fetchRandomVerb,
    fetchRandomNoun,
    fetchRandomProduct,
  ]);

  useEffect(() => {
    generateIdea();
  }, []);

  const removeSpaces = (str: string): string => {
    return str.replace(/\s/g, "");
  };

  return (
    <>
      <Container fluid className="font-bold d-none d-lg-block">
        <Header />
        <Row className="big-text text-uppercase d-inline-block statement-text w-100 text-center text-wrap pt-5 mt-5 font-ultra ">
          {article}{" "}
          <mark>
            <WordState
              word={removeSpaces(product.word)}
              className=""
              state={product.locked}
              onClick={() =>
                setProduct({
                  ...product,
                  locked: !product.locked,
                })
              }
            />
          </mark>{" "}
          THAT
          <br />
          <mark>
            <WordState
              word={removeSpaces(verb.word)}
              className=""
              state={verb.locked}
              onClick={() =>
                setVerb({
                  ...verb,
                  locked: !verb.locked,
                })
              }
            />
          </mark>
          <span> </span>
          <mark>
            <WordState
              word={removeSpaces(noun.word)}
              className=""
              state={noun.locked}
              onClick={() =>
                setNoun({
                  ...noun,
                  locked: !noun.locked,
                })
              }
            />
          </mark>
          {". "}
        </Row>
        <Row className="w-100 text-center position-static">
          <Col className="pt-5">
            <button
              className="bold-text crap-button-black-lg"
              onClick={() => generateIdea()}
            >
              REMIX
            </button>
          </Col>
        </Row>
        <Row className="w-100 ">
          <Col className="ps-5 ms-5 pt-5 mt-lg-5 mt-xl-5">
            <Link to="/add" className="text-decoration-none text-black">
              <div className="bold-text crap-button-outlined-lg">ADD IDEAS</div>
            </Link>
          </Col>
          <Col className="ps-5 ms-5 pt-5 mt-lg-4 mt-xl-4">
            <Row className="text-center">
              {" "}
              <h5>SHARE THIS IDEA</h5>
            </Row>

            <Row className="mx-auto d-block text-center w-75">
              <a href="#" className="pe-0">
                <img
                  className="crap-social-icons pe-0"
                  src="/images/copy.png"
                  alt="copy"
                  onClick={() => {
                    const idea =
                      article +
                      " " +
                      removeSpaces(product.word) +
                      " that " +
                      removeSpaces(verb.word) +
                      " " +
                      removeSpaces(noun.word) +
                      ".";
                    navigator.clipboard.writeText(idea);
                    setTimeout(function() {
                      alert(`Copied your idea: ${idea}`);
                    }, 1000);
                  }}
                />
              </a>
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A//ideagenerator.crapideas.com/"
                className="pe-0"
              >
                <img
                  className="crap-social-icons pe-0"
                  src="/images/facebook.png"
                  alt="fb"
                />
              </a>
              <a
                href="https://twitter.com/intent/tweet?text=Generate%20ideas%20at%20https%3A//ideagenerator.crapideas.com/"
                className="pe-0"
              >
                <img
                  className="crap-social-icons pe-0"
                  src="/images/twitter.png"
                  alt="tw"
                />
              </a>
              <a href="#" className="pe-0">
                <img
                  className="crap-social-icons pe-0"
                  src="/images/instagram.png"
                  alt="ig"
                />
              </a>{" "}
              <a href="#" className="pe-0">
                <img
                  className="crap-social-icons pe-0"
                  src="/images/email.png"
                  alt="email"
                />
              </a>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* Mobile  */}
      <Container fluid className="d-lg-none">
        <Header />
        <Row className="big-text text-uppercase d-inline-block statement-text w-100 text-center pt-5 ms-1 font-ultra generator-mobile">
          {article}{" "}
          <mark>
            <WordState
              word={removeSpaces(product.word)}
              className=""
              state={product.locked}
              onClick={() =>
                setProduct({
                  ...product,
                  locked: !product.locked,
                })
              }
            />
          </mark>{" "}
          THAT
          <br />
          <mark>
            <WordState
              word={removeSpaces(verb.word)}
              className=""
              state={verb.locked}
              onClick={() =>
                setVerb({
                  ...verb,
                  locked: !verb.locked,
                })
              }
            />
          </mark>{" "}
          <WordState
            word={removeSpaces(noun.word)}
            className="highlight-word"
            state={noun.locked}
            onClick={() =>
              setNoun({
                ...noun,
                locked: !noun.locked,
              })
            }
          />
          {". "}
        </Row>
        <Row className="w-100 text-center">
          <Col className="ms-5 pt-5">
            <Row className="w-100">
              <button
                className="bold-text crap-button-black-lg"
                onClick={() => generateIdea()}
              >
                REMIX
              </button>
            </Row>
          </Col>
        </Row>
        <Row className="w-100  pt-3">
          <Col className="ms-5 text-center">
            <Row className="w-100">
              <button
                className="bold-text crap-button-outlined-lg"
                onClick={() => navigate(`/add`)}
              >
                ADD IDEAS
              </button>
            </Row>
          </Col>
        </Row>

        <Row className="text-center pt-5 font-bold">
          <h5>SHARE THIS IDEA</h5>
        </Row>

        <Row className=" d-block justify-content-center  text-center">
          <a href="#" className="pe-0">
            <img
              className="crap-social-icons pe-0"
              src="/images/copy.png"
              alt="copy"
              onClick={() => {
                const idea =
                  article +
                  " " +
                  removeSpaces(product.word) +
                  " that " +
                  removeSpaces(verb.word) +
                  " " +
                  removeSpaces(noun.word) +
                  ".";

                navigator.clipboard.writeText(idea);

                setTimeout(function() {
                  alert(`Copied your idea: ${idea}`);
                }, 1000);
              }}
            />
          </a>
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A//ideagenerator.crapideas.com/"
            className="pe-0"
          >
            <img
              className="crap-social-icons pe-0"
              src="/images/facebook.png"
              alt="fb"
            />
          </a>
          <a
            href="https://twitter.com/intent/tweet?text=Generate%20ideas%20at%20https%3A//ideagenerator.crapideas.com/"
            className="pe-0"
          >
            <img
              className="crap-social-icons pe-0"
              src="/images/twitter.png"
              alt="tw"
            />
          </a>
          <a href="#" className="pe-0">
            <img
              className="crap-social-icons pe-0"
              src="/images/instagram.png"
              alt="ig"
            />
          </a>{" "}
          <a href="#" className="pe-0">
            <img
              className="crap-social-icons pe-0"
              src="/images/email.png"
              alt="email"
            />
          </a>
        </Row>
      </Container>
    </>
  );
};

export default ShitIdeas;
