import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Header from "../components/header";
import { Link, useNavigate } from "react-router-dom";

const DumpIdeas = () => {
  const [word, setWord] = useState("");
  const [wordType, setWordType] = useState("Verb");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  let navigate = useNavigate();

  const submitWords = async (word: string, wordType: string) => {
    await fetch("https://dump-ideas-api.herokuapp.com/addWord", {
      method: "POST",
      body: JSON.stringify({
        word: word.toLowerCase(),
        wordType: wordType,
      }),
    })
      .then((res) => {
        res.json().then((r) => {
          if (r.status === true) {
            setMessage("Yay! This word has been added successfully.");
            setStatus("crap-successful");
          } else {
            setMessage("Sorry! This word can't be added.");
            setStatus("crap-fail");
          }

          console.log(r);
        });
      })
      .catch((err) => {
        console.log("may error", err);
      });
  };

  return (
    <>
      {/* Desktop */}
      <Container fluid className="font-regular d-none d-lg-block">
        <Header />

        <Row className=" mt-5 pt-5 d-inline-block w-100 text-center">
          <div className="text-center color-black font-bold">
            <h1>Enter words to help create ideas.</h1>
          </div>
        </Row>
        <div className="d-flex justify-content-center">
          <InputGroup className="pt-5 mt-5 w-75">
            <DropdownButton
              variant="outline-dark"
              title={wordType}
              align="end"
              id="crap-dropdown"
              className="border-dark border-3"
            >
              {wordType === "Verb" ? (
                <Dropdown.Item onClick={() => setWordType("Noun")}>
                  Noun
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={() => setWordType("Verb")}>
                  Verb
                </Dropdown.Item>
              )}
            </DropdownButton>

            <FormControl
              aria-label="Text input with dropdown button"
              className="text-center crap-round-button font-bold crap-input-big border-dark border-3"
              id="input-dump"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  submitWords(word, wordType);
                }
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWord(e.target.value)
              }
            />
          </InputGroup>
        </div>
        <Row>
          <span className={`text-center font-bold ${status}`}>{message}</span>
        </Row>
        <Row className="mx-auto text-center w-50 mt-5 mb-5 pb-5">
          <Col>
            <button
              className="bold-text crap-button-black-lg font-bold"
              onClick={() => submitWords(word, wordType)}
              disabled={word ? false : true}
            >
              ADD TO LIBRARY
            </button>
          </Col>
        </Row>
        <Row className="w-100">
          <Col className="d-flex flex-row-reverse pe-5 me-5">
            <Link to="/generate" className="text-decoration-none text-black">
              <div className="font-bold crap-button-outlined-lg">
                REMIX IDEAS
              </div>
            </Link>
          </Col>
        </Row>
      </Container>

      {/* Mobile*/}
      <Container fluid className="font-regular d-block d-lg-none ">
        <Header />
        <Row className="pt-5 text-center pt-5 mt-5">
          <div className="color-black font-bold">
            <h5>Enter words to help create ideas.</h5>
          </div>

          <InputGroup className="mb-3 d-flex justify-content-center">
            <DropdownButton
              variant="outline-dark"
              title={wordType}
              align="end"
              id="crap-dropdown"
            >
              {wordType === "Verb" ? (
                <Dropdown.Item onClick={() => setWordType("Noun")}>
                  <span className="font-regular">Noun</span>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={() => setWordType("Verb")}>
                  Verb
                </Dropdown.Item>
              )}
            </DropdownButton>

            <input
              aria-label="Text input with dropdown button"
              className="text-center crap-round-button font-bold border-dark border-3"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWord(e.target.value)
              }
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  submitWords(word, wordType);
                }
              }}
            />
          </InputGroup>
        </Row>
        <Row>
          <span className={`text-center font-bold ${status}`}>{message}</span>
        </Row>
        <Row className="mx-auto text-center w-75 pt-5">
          <button
            className="font-bold crap-button-black-lg-mfont pt-3 pb-3"
            onClick={() => submitWords(word, wordType)}
            disabled={word ? false : true}
          >
            ADD TO LIBRARY
          </button>
        </Row>
        <Row className=" mx-auto text-center w-75 pt-3">
          <button
            className="font-bold crap-button-outlined-lg "
            onClick={() => {
              navigate("/generate");
            }}
          >
            REMIX IDEAS
          </button>
        </Row>
      </Container>
    </>
  );
};

export default DumpIdeas;
